// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* stylelint-disable */\n\n/* stylelint-enable */\n/* stylelint-disable */\n\n/* stylelint-enable */\n.collection-list-item {\n  position: relative;\n  display: flex;\n  padding: 10px 18px; }\n  .collection-list-item__indicator {\n    position: absolute;\n    left: 0;\n    top: 0;\n    bottom: 0;\n    width: 4px; }\n  .collection-list-item__info {\n    display: flex;\n    flex-direction: column;\n    flex: 1 1;\n    overflow: hidden;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    margin-right: 8px; }\n  .collection-list-item__name {\n    font-family: \"PT Root UI\";\n    font-weight: 500;\n    font-size: 16px;\n    color: #000;\n    margin-bottom: 6px;\n    overflow: hidden;\n    text-overflow: ellipsis; }\n  .collection-list-item__object {\n    font-family: \"PT Root UI\";\n    font-weight: 500;\n    font-size: 12px;\n    color: rgba(0, 0, 0, 0.25);\n    margin: 0 8px;\n    vertical-align: top; }\n  .collection-list-item__application {\n    font-family: \"PT Root UI\";\n    font-weight: 700;\n    font-size: 15px;\n    color: #0099aa; }\n  .collection-list-item__data {\n    display: flex;\n    width: 520px;\n    flex-shrink: 0;\n    justify-content: flex-end; }\n", ""]);
// Exports
exports.locals = {
	"color-background": "#f0f7fb",
	"color-primary": "#0099aa",
	"color-primary-dark": "#2f565a",
	"color-secondary": "#fa9806",
	"color-error": "#ff0000",
	"color-red": "#fa0f63",
	"color-blue": "#0086e7",
	"color-border": "#dae6e8",
	"color-grey": "#9d9d9d",
	"color-green": "#00BB27",
	"font-roboto": "\"Roboto\"",
	"font-roboto-cond": "\"Roboto Condensed\"",
	"font-roboto-mono": "\"Roboto Mono\"",
	"font-pt": "\"PT Root UI\""
};
module.exports = exports;
