// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* stylelint-disable */\n\n/* stylelint-enable */\n/* stylelint-disable */\n\n/* stylelint-enable */\n.textarea {\n  font-family: \"Roboto\";\n  font-weight: 400;\n  -webkit-overflow-scrolling: touch;\n  border: 1px solid #c5cdd0;\n  outline: none;\n  border-radius: 2px;\n  background: #fff;\n  padding: 9px 8px;\n  font-size: 15px;\n  line-height: 24px;\n  resize: none; }\n  .textarea::-webkit-scrollbar {\n    width: 2px;\n    background: transparent; }\n  .textarea::-webkit-scrollbar-track {\n    width: 2px;\n    background-color: rgba(47, 86, 90, 0.4); }\n  .textarea::-webkit-scrollbar-thumb {\n    background: #0099aa;\n    border-radius: 0; }\n  .textarea::-webkit-scrollbar-button {\n    display: none; }\n", ""]);
// Exports
exports.locals = {
	"color-background": "#f0f7fb",
	"color-primary": "#0099aa",
	"color-primary-dark": "#2f565a",
	"color-secondary": "#fa9806",
	"color-error": "#ff0000",
	"color-red": "#fa0f63",
	"color-blue": "#0086e7",
	"color-border": "#dae6e8",
	"color-grey": "#9d9d9d",
	"color-green": "#00BB27",
	"font-roboto": "\"Roboto\"",
	"font-roboto-cond": "\"Roboto Condensed\"",
	"font-roboto-mono": "\"Roboto Mono\"",
	"font-pt": "\"PT Root UI\""
};
module.exports = exports;
