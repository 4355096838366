// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* stylelint-disable */\n\n/* stylelint-enable */\n.header {\n  padding: 0 44px 0 50px;\n  height: 64px;\n  display: flex;\n  align-items: center;\n  background: rgba(255, 255, 255, 0.95);\n  border-bottom: 1px solid rgba(188, 200, 202, 0.4);\n  z-index: 10;\n  position: -webkit-sticky;\n  position: sticky;\n  top: 0;\n  flex-shrink: 0; }\n  .header__nav {\n    display: flex;\n    align-self: stretch;\n    flex: 1 1;\n    justify-content: flex-end; }\n  .header__application {\n    align-self: center;\n    margin-right: 48px; }\n  .header__language {\n    margin-left: 44px; }\n  .header__person {\n    margin-left: 28px;\n    display: flex;\n    align-items: center;\n    position: relative; }\n    .header__person-button:hover {\n      opacity: 0.7; }\n    .header__person-menu {\n      min-width: 250px;\n      position: absolute;\n      background: #fff;\n      -webkit-filter: drop-shadow(0px 8px 18px rgba(0, 0, 0, 0.3));\n              filter: drop-shadow(0px 8px 18px rgba(0, 0, 0, 0.3));\n      bottom: -10px;\n      -webkit-transform: translateY(100%);\n              transform: translateY(100%);\n      right: -22px; }\n      .header__person-menu:before {\n        content: '';\n        display: block;\n        position: absolute;\n        top: 0;\n        right: 26px;\n        -webkit-transform: translate(-50%, -50%) rotate(45deg);\n                transform: translate(-50%, -50%) rotate(45deg);\n        width: 10px;\n        height: 10px;\n        background: #fff; }\n", ""]);
// Exports
exports.locals = {
	"color-background": "#f0f7fb",
	"color-primary": "#0099aa",
	"color-primary-dark": "#2f565a",
	"color-secondary": "#fa9806",
	"color-error": "#ff0000",
	"color-red": "#fa0f63",
	"color-blue": "#0086e7",
	"color-border": "#dae6e8",
	"color-grey": "#9d9d9d",
	"color-green": "#00BB27",
	"font-roboto": "\"Roboto\"",
	"font-roboto-cond": "\"Roboto Condensed\"",
	"font-roboto-mono": "\"Roboto Mono\"",
	"font-pt": "\"PT Root UI\""
};
module.exports = exports;
