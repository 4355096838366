// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* stylelint-disable */\n\n/* stylelint-enable */\n/* stylelint-disable */\n\n/* stylelint-enable */\n.header-nav-item {\n  font-family: \"PT Root UI\";\n  font-weight: 700;\n  display: flex;\n  align-items: center;\n  text-decoration: none;\n  color: #0099aa;\n  position: relative;\n  white-space: nowrap; }\n  .header-nav-item:not(:last-child) {\n    margin-right: 56px; }\n  .header-nav-item--active {\n    color: #2f565a; }\n    .header-nav-item--active::after {\n      content: '';\n      position: absolute;\n      bottom: 0;\n      left: 0;\n      right: 0;\n      height: 3px;\n      background: #0099aa; }\n", ""]);
// Exports
exports.locals = {
	"color-background": "#f0f7fb",
	"color-primary": "#0099aa",
	"color-primary-dark": "#2f565a",
	"color-secondary": "#fa9806",
	"color-error": "#ff0000",
	"color-red": "#fa0f63",
	"color-blue": "#0086e7",
	"color-border": "#dae6e8",
	"color-grey": "#9d9d9d",
	"color-green": "#00BB27",
	"font-roboto": "\"Roboto\"",
	"font-roboto-cond": "\"Roboto Condensed\"",
	"font-roboto-mono": "\"Roboto Mono\"",
	"font-pt": "\"PT Root UI\""
};
module.exports = exports;
