// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* stylelint-disable */\n\n/* stylelint-enable */\n/* stylelint-disable */\n\n/* stylelint-enable */\n.report-popup {\n  background-color: #fbfbfb;\n  padding: 40px 46px 42px;\n  width: 540px;\n  max-width: 100%; }\n  .report-popup__title {\n    margin: 0 0 36px; }\n  .report-popup__label {\n    display: inline-block;\n    font-family: \"Roboto\";\n    font-weight: 400; }\n  .report-popup__input {\n    display: block;\n    width: 100%;\n    margin-bottom: 16px;\n    border-color: #cddaee; }\n  .report-popup__textarea {\n    display: block;\n    width: 100%;\n    margin-bottom: 50px;\n    border-color: #cddaee; }\n  .report-popup__footer {\n    display: flex;\n    align-items: center; }\n  .report-popup__pending-loader {\n    margin-left: 16px;\n    margin-right: 8px; }\n", ""]);
// Exports
exports.locals = {
	"color-background": "#f0f7fb",
	"color-primary": "#0099aa",
	"color-primary-dark": "#2f565a",
	"color-secondary": "#fa9806",
	"color-error": "#ff0000",
	"color-red": "#fa0f63",
	"color-blue": "#0086e7",
	"color-border": "#dae6e8",
	"color-grey": "#9d9d9d",
	"color-green": "#00BB27",
	"font-roboto": "\"Roboto\"",
	"font-roboto-cond": "\"Roboto Condensed\"",
	"font-roboto-mono": "\"Roboto Mono\"",
	"font-pt": "\"PT Root UI\""
};
module.exports = exports;
