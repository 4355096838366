// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* stylelint-disable */\n\n/* stylelint-enable */\n/* stylelint-disable */\n\n/* stylelint-enable */\n.person-menu {\n  display: flex;\n  flex-direction: column;\n  border-radius: 2px; }\n  .person-menu__content {\n    padding: 10px 30px;\n    border: 1px solid #dbe4e7;\n    display: flex;\n    flex-direction: column; }\n  .person-menu__current-role {\n    font-family: \"PT Root UI\";\n    font-weight: 300;\n    margin-bottom: 4px; }\n    .person-menu__current-role b {\n      font-weight: bold; }\n  .person-menu__role {\n    padding: 12px 0;\n    text-align: left;\n    white-space: nowrap; }\n  .person-menu__logout {\n    margin: 10px 30px; }\n    .person-menu__logout-button {\n      text-align: left;\n      width: 100%; }\n", ""]);
// Exports
exports.locals = {
	"color-background": "#f0f7fb",
	"color-primary": "#0099aa",
	"color-primary-dark": "#2f565a",
	"color-secondary": "#fa9806",
	"color-error": "#ff0000",
	"color-red": "#fa0f63",
	"color-blue": "#0086e7",
	"color-border": "#dae6e8",
	"color-grey": "#9d9d9d",
	"color-green": "#00BB27",
	"font-roboto": "\"Roboto\"",
	"font-roboto-cond": "\"Roboto Condensed\"",
	"font-roboto-mono": "\"Roboto Mono\"",
	"font-pt": "\"PT Root UI\""
};
module.exports = exports;
