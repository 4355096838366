// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* stylelint-disable */\n\n/* stylelint-enable */\n/* stylelint-disable */\n\n/* stylelint-enable */\n.data-row {\n  display: flex; }\n  .data-row__item {\n    font-family: \"Roboto\";\n    font-weight: 400;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between; }\n    .data-row__item-header {\n      font-size: 13px;\n      color: #9d9d9d; }\n    .data-row__item-body {\n      font-size: 15px;\n      color: #0099aa; }\n", ""]);
// Exports
exports.locals = {
	"color-background": "#f0f7fb",
	"color-primary": "#0099aa",
	"color-primary-dark": "#2f565a",
	"color-secondary": "#fa9806",
	"color-error": "#ff0000",
	"color-red": "#fa0f63",
	"color-blue": "#0086e7",
	"color-border": "#dae6e8",
	"color-grey": "#9d9d9d",
	"color-green": "#00BB27",
	"font-roboto": "\"Roboto\"",
	"font-roboto-cond": "\"Roboto Condensed\"",
	"font-roboto-mono": "\"Roboto Mono\"",
	"font-pt": "\"PT Root UI\""
};
module.exports = exports;
