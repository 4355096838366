// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* stylelint-disable */\n\n/* stylelint-enable */\n/* stylelint-disable */\n\n/* stylelint-enable */\n.collection-permission {\n  display: flex;\n  align-items: center;\n  justify-content: space-between; }\n  .collection-permission__application {\n    font-family: \"PT Root UI\";\n    font-weight: 500;\n    font-size: 16px;\n    line-height: 20px;\n    color: #000; }\n  .collection-permission__select {\n    width: 180px; }\n    .collection-permission__select .select__value-container {\n      justify-content: flex-end; }\n    .collection-permission__select .select__control {\n      background: transparent; }\n    .collection-permission__select .select__option,\n    .collection-permission__select .select__value-container,\n    .collection-permission__select .select__single-value {\n      font-family: \"PT Root UI\";\n      font-weight: 400;\n      color: #3f3f55;\n      font-size: 14px;\n      text-align: right; }\n    .collection-permission__select .select__option:not(:last-child) {\n      border-bottom: none; }\n    .collection-permission__select .select__option--is-focused, .collection-permission__select .select__option:hover {\n      background: #f0f7fb; }\n    .collection-permission__select .select__option--is-selected, .collection-permission__select .select__option:active {\n      background: inherit; }\n    .collection-permission__select .select__option:last-child {\n      color: #fa0f63; }\n    .collection-permission__select .select__menu {\n      border: none;\n      background: #ffffff;\n      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);\n      border-radius: 3px; }\n", ""]);
// Exports
exports.locals = {
	"color-background": "#f0f7fb",
	"color-primary": "#0099aa",
	"color-primary-dark": "#2f565a",
	"color-secondary": "#fa9806",
	"color-error": "#ff0000",
	"color-red": "#fa0f63",
	"color-blue": "#0086e7",
	"color-border": "#dae6e8",
	"color-grey": "#9d9d9d",
	"color-green": "#00BB27",
	"font-roboto": "\"Roboto\"",
	"font-roboto-cond": "\"Roboto Condensed\"",
	"font-roboto-mono": "\"Roboto Mono\"",
	"font-pt": "\"PT Root UI\""
};
module.exports = exports;
