// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* stylelint-disable */\n\n/* stylelint-enable */\n/* stylelint-disable */\n\n/* stylelint-enable */\n.dictionary-list-item {\n  padding: 0; }\n  .dictionary-list-item__name {\n    font-family: \"PT Root UI\";\n    font-weight: 500;\n    font-size: 16px;\n    color: #000;\n    padding: 12px 16px;\n    border-bottom: 1px solid rgba(29, 33, 37, 0.1);\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis; }\n  .dictionary-list-item__data {\n    padding: 8px 16px; }\n    .dictionary-list-item__data-header {\n      margin-bottom: 8px; }\n", ""]);
// Exports
exports.locals = {
	"color-background": "#f0f7fb",
	"color-primary": "#0099aa",
	"color-primary-dark": "#2f565a",
	"color-secondary": "#fa9806",
	"color-error": "#ff0000",
	"color-red": "#fa0f63",
	"color-blue": "#0086e7",
	"color-border": "#dae6e8",
	"color-grey": "#9d9d9d",
	"color-green": "#00BB27",
	"font-roboto": "\"Roboto\"",
	"font-roboto-cond": "\"Roboto Condensed\"",
	"font-roboto-mono": "\"Roboto Mono\"",
	"font-pt": "\"PT Root UI\""
};
module.exports = exports;
