// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* stylelint-disable */\n\n/* stylelint-enable */\n/* stylelint-disable */\n\n/* stylelint-enable */\n.page-placeholder {\n  -webkit-animation: opacity 1.2s ease-in-out;\n          animation: opacity 1.2s ease-in-out;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100vh; }\n\n@-webkit-keyframes opacity {\n  from {\n    opacity: 0; }\n  to {\n    opacity: 1; } }\n\n@keyframes opacity {\n  from {\n    opacity: 0; }\n  to {\n    opacity: 1; } }\n  .page-placeholder__logo {\n    -webkit-animation: pulse 2s linear infinite;\n            animation: pulse 2s linear infinite; }\n\n@-webkit-keyframes pulse {\n  0% {\n    -webkit-transform: scale(1, 1);\n            transform: scale(1, 1); }\n  50% {\n    -webkit-transform: scale(1.25, 1.25);\n            transform: scale(1.25, 1.25); }\n  100% {\n    -webkit-transform: scale(1, 1);\n            transform: scale(1, 1); } }\n\n@keyframes pulse {\n  0% {\n    -webkit-transform: scale(1, 1);\n            transform: scale(1, 1); }\n  50% {\n    -webkit-transform: scale(1.25, 1.25);\n            transform: scale(1.25, 1.25); }\n  100% {\n    -webkit-transform: scale(1, 1);\n            transform: scale(1, 1); } }\n", ""]);
// Exports
exports.locals = {
	"color-background": "#f0f7fb",
	"color-primary": "#0099aa",
	"color-primary-dark": "#2f565a",
	"color-secondary": "#fa9806",
	"color-error": "#ff0000",
	"color-red": "#fa0f63",
	"color-blue": "#0086e7",
	"color-border": "#dae6e8",
	"color-grey": "#9d9d9d",
	"color-green": "#00BB27",
	"font-roboto": "\"Roboto\"",
	"font-roboto-cond": "\"Roboto Condensed\"",
	"font-roboto-mono": "\"Roboto Mono\"",
	"font-pt": "\"PT Root UI\""
};
module.exports = exports;
