// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* stylelint-disable */\n\n/* stylelint-enable */\n.heading {\n  font-family: \"Roboto\";\n  font-weight: 300; }\n\nh2.heading {\n  font-size: 28px;\n  line-height: 33px; }\n\nh3.heading {\n  font-size: 24px;\n  line-height: 33px; }\n", ""]);
// Exports
exports.locals = {
	"color-background": "#f0f7fb",
	"color-primary": "#0099aa",
	"color-primary-dark": "#2f565a",
	"color-secondary": "#fa9806",
	"color-error": "#ff0000",
	"color-red": "#fa0f63",
	"color-blue": "#0086e7",
	"color-border": "#dae6e8",
	"color-grey": "#9d9d9d",
	"color-green": "#00BB27",
	"font-roboto": "\"Roboto\"",
	"font-roboto-cond": "\"Roboto Condensed\"",
	"font-roboto-mono": "\"Roboto Mono\"",
	"font-pt": "\"PT Root UI\""
};
module.exports = exports;
