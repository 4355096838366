// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* stylelint-disable */\n\n/* stylelint-enable */\n/* stylelint-disable */\n\n/* stylelint-enable */\n.create-request-popup {\n  background: #fff;\n  border-radius: 2px;\n  width: 800px; }\n  .create-request-popup__header {\n    background: #f0f7fb;\n    padding: 40px 38px 18px; }\n  .create-request-popup__title {\n    font-family: \"Roboto\";\n    font-weight: 300;\n    font-size: 28px;\n    line-height: 33px;\n    color: #000;\n    margin-bottom: 28px; }\n  .create-request-popup__dictionary {\n    margin-bottom: 18px; }\n  .create-request-popup__counters {\n    display: flex;\n    align-items: center; }\n  .create-request-popup__counter {\n    margin-right: 70px; }\n  .create-request-popup__form {\n    padding: 18px 38px; }\n  .create-request-popup__error {\n    padding: 0 38px 18px; }\n", ""]);
// Exports
exports.locals = {
	"color-background": "#f0f7fb",
	"color-primary": "#0099aa",
	"color-primary-dark": "#2f565a",
	"color-secondary": "#fa9806",
	"color-error": "#ff0000",
	"color-red": "#fa0f63",
	"color-blue": "#0086e7",
	"color-border": "#dae6e8",
	"color-grey": "#9d9d9d",
	"color-green": "#00BB27",
	"font-roboto": "\"Roboto\"",
	"font-roboto-cond": "\"Roboto Condensed\"",
	"font-roboto-mono": "\"Roboto Mono\"",
	"font-pt": "\"PT Root UI\""
};
module.exports = exports;
